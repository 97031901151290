@import "~antd/dist/antd.css";
@font-face {
  font-family: HelveticaNeueBold;
  src: url('../src/assets/fonts/HelveticaNue/HelveticaNeueBold.ttf');
}
@font-face {
  font-family: HelveticaNeue-Light;
  src: url('../src/assets/fonts/HelveticaNue/HelveticaNeue-Light.otf');
}
@font-face {
  font-family: HelveticaNeue-Medium;
  src: url('../src/assets/fonts/HelveticaNue/HelveticaNeue-Medium.otf');
}
@font-face {
  font-family: HelveticaNeue-Thin;
  src: url('../src/assets/fonts/HelveticaNue/HelveticaNeue-Thin.otf');
}
.is-clipped {
  overflow: hidden !important;
}

.footer {
  margin-top: 150px;
  background-color: #712B81;
}

@media only screen and (min-width : 320px) {
  .modal-image {
    width: 320px;
  }
}

@media only screen and (min-width : 480px) {
  .modal-image {
    width: 360px;
  }
}

@media only screen and (min-width : 768px) {
  .modal-image {
    width: 640px;
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    margin-top: 150px;
    padding: 0px;
  }
}

@media only screen and (min-width : 992px) {
  .modal-image {
    width: 640px;
  }
}

/* custom css */
.ant-input {
  background: #FFF;
}

.ant-checkbox-inner {
  background: #FFF;
}

#auth {
  background: #FFF;
}

.ant-layout {
  background: #FFF;
}


.ant-select-selection {
  background: #FFF;
}

.background-primary {
  background-color: #612466;
}

.background-secondary {
  background-color: #00B4AA;
}

.ant-modal-title {
  color: #612466;
  font-weight: bold;
}

.color-primary {
  color: #612466;
}

.color-secondary {
  color: #00B4AA;
}

.text-gray {
  color: #707070;
}

.ant-form-item-label label {
  color: #612466;
  font-weight: bold;
}

.section-color {
  color: #712B81; 
}

thead[class*="ant-table-thead"] th{
  color: #712B81 !important;
  font-weight: 600;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border: 0;
  background-color: transparent;
}

.ant-pagination-item {
  border: 0;
  background-color: transparent;
  border-radius: 50px;
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
  color: #fff;
}

.ant-pagination-item-active {
  color: #fff;
  background-color: #712B81;
}
