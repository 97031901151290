.entrego-footer {
  padding: 0 20px 20px 20px;
}
.login-icons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
}
.login-icons > i{
  transform: scale(3);
    font-size: 0;
}
.footer-link {
  color: #fff;
}

.divider-logo {
  position: relative;
  top: -65px;
}

.footer-logo {
  min-height: 28px;
  min-width: 160px;
  margin-bottom: 12px;
}

.footer-mission {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.21px;
}

.footer-copyright {
  margin-top: 15px;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.21px;
}

.social-plugins {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 11px;
}
