.header {
  position: relative;
  z-index: 2;
  min-height: 64px;
  background: transparent;
  padding: 10px 0;
  align-items: center;
  /* box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2); */
}

.pointer {
  cursor: pointer;
}

.header a:hover,
.header a:focus {
  text-decoration: none;
  color: #707070;
}

.header a {
  color: #707070;
  /* border: 1px solid white; */
}

.icon-container {
  cursor: pointer;
  display: flex;
  min-width: 30px;
  justify-content: center;
}

.details-contact {
  color: #fff;
}

.logo {
  display: block;
  min-width: 148px;
  height: 40px;
}

.icon-burger {
  display: none !important;
}

.profile-modal {
  display: none;
}

.margin-five {
  margin-right: 3rem !important;
}

.margin-five-rl {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.font-icon {
  font-size: 22px;
}
@media only screen and (max-width: 991px) {
  
  .header {
    justify-content: space-between !important;
    padding: 10px 15px;
  }

  .icon-burger {
    display: block !important;
  }

  .header-collapse-item {
    display: none !important;
  }

  .collapse-item {
    display: none !important;
  }

  .expand-item {
    display: block !important;
  }

  .profile-modal {
    overflow: hidden;
    display: block;
  }

  .profile-modal .ant-modal-body {
    padding: 0;
  }

  .profile-modal .ant-modal-close {
    color: #707070;
  }
  .margin-five {
    margin-right: 8px !important;
  }

  .margin-five-rl {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  
}
.ant-dropdown-menu-item img{
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.ant-dropdown-menu-item a{
  color: #712B81 !important;
}
.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
  padding: 7px 15px !important;
  color: #712B81 !important;
}
.anticon.anticon-user, .anticon.anticon-environment, .anticon.anticon-bank, .anticon.anticon-logout{
  padding: 0px 10px 0px 0px;
}
.ant-dropdown-menu hr{
  margin-top:10px;
  margin-bottom:5px;
}