.material-input {
  border: 0px;
  font-size: 16px;
  border-radius: 5px !important;
  /* border-bottom: 1px solid #767676; */
}

/* .material-input:focus {
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #767676;
  box-shadow: none;
} */

.profile-label-text {
  color: #767676;
  font-size: 12px;
}

.entrego-select>.ant-select-selection, .entrego-select>.ant-select-selection:hover {
  width: 100%;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  /* border-bottom: 1px solid #767676; */
  box-shadow: none;
}

.ant-row .ant-form-item .ant-form-item-control {
  margin-bottom: 0px;
  line-height: 15px;
}

.mt-3 {
  margin-top: 3rem;
}

.pouchbox-tooltip-style {
  border: none !important;
  background: none !important;
  padding: 0 !important;
  height: 12px !important;
  width: 12px !important;
  margin-left: 5px;
}

.pouchbox-tooltip-style:focus {
  background-color: #FAFAFA !important;
  border-color: #FAFAFA !important;
}

.bankproof-label-text {
  color: #712B81;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.bankproof-text-style {
  color: #712B81;
  font-size: 13px;
}

.upload-proof-btn-style {
  background: #50B1A9 !important;
  width: 460px !important;
  height: 35px !important;
  color: #FFF !important;
  margin-top: 15px;
}

.proof-success-label-style {
  color: #06BF38;
  font-size: 16px;
  margin-top: 10px;
}

.proof-failure-label-style {
  color: #BF391E;
  font-size: 16px;
  margin-top: 10px;
}

.bank-proof-title {
  color: #BF391E;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.bank-proof-content {
  color: #712B81;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  margin-bottom: 20px;
}

.upload-proof-modal-style {
  width: 450px !important
}

.button-align {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.bank-ok-btn-style,
.bank-ok-btn-style:hover,
.bank-ok-btn-style:focus {
  width: 30% !important;
  color: #FFFFFF !important;
  border: 1px solid #712B81 !important;
  background: #712B81 !important;
  height: 50px !important;
  font-size: 14px !important;
}