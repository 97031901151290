.finalize-popup-spacebar {
  margin: 30px 0 35px 0;
  text-align: center;
  color: #343A40  ;
  font-size: 15px;
}


.session-timeout-modal>.ant-modal-content>.ant-modal-close>.ant-modal-close-x {
  display: none !important;
}

.add-piclup-divider-style {
  background-color: #50B1A9 !important; 
  width: 100% !important;
  height: 1.5px !important;
  align-items: flex-start;
  margin: 5px 0 5px 0 !important;
  margin-bottom: 20px !important;
}

.space-btwn-btns {
  display: flex;
  justify-content: flex-end;
}

.session-stay-btn-style,
.session-stay-btn-style:hover,
.session-stay-btn-style:focus {
  width: 100%;
  color: #FFFFFF;
  background: #712B81 !important;
  height: 40px;
  font-size: 13px;
}

.session-logout-btn-style,
.session-logout-btn-style:hover,
.session-logout-btn-style:focus {
  width: 100%;
  color: #FFFFFF;
  border: 1px solid #868686;
  background: #868686;
  height: 40px;
  font-size: 13px;
}