.modified-header {
  position: relative;
  z-index: 2;
  min-height: 50px;
  background: #f8f8f8;
  padding: 0px;
  align-items: center;
  height: 5vh;
  border-bottom: 2px groove rgba(112, 112, 112, 0.28);
}

.search-text::placeholder {
  color: #054165 !important;
  opacity: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 27px;
}

.breadcum-style {
  color: #43425D !important;
  font-style: normal;
  font-size: 11px;
  line-height: 22px;
}

.search-text {
  background: rgba(221, 221, 221, 0.51);
  height: 25px;
  border-radius: 0px !important;
}

.cusm-svg-container>svg {
  width: 8px;
  height: 8px
}

.dropdown-style {
  color: #712B81 !important;
  font-style: normal;
  font-size: 10px !important;
  line-height: 15px !important;
  background-color: none !important;
}

.ant-breadcrumb-link {
  color: #712B81 !important;
  font-style: normal;
  font-size: 13px !important;
}

#dropdown-icon-style {
  width: 12px !important;
  height: 12px !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: none !important;
}

.divider-style {
  border: 0.4px solid rgba(112, 112, 112, 0.1);
  width: 40%;
  margin: 3px auto 3px auto !important;
}

.hand-icon-continer {
  text-align: center;
  margin-left: 22px;
}

.hand-icon-continer>svg {
  height: 13px;
  width: 20px;
  vertical-align: bottom;
  cursor: pointer;
}

#MainLogoBooking {
  width: 150px;
  margin-left: 23px;
}