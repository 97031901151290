@import "../src/modules/booking/pages/add-package/styles.css";
@import "../src/modules/booking/pages/update-package/styles.css";
@import "../src/modules/booking/pages/bulk-upload/styles.css";
@import "../src/commons/List/styles.css"; 

body {
  margin: 0;
  padding: 0;
  font-family: Chivo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .fb_customer_chat_bounce_in_v2 {
  left: 0pt !important;
}

.fb_dialog {
  left: 18pt !important;
} */

/* ***************
 * FB on left side 
 ******************/
/* this is for the circle position */
.fb_dialog.fb_dialog_advanced {
  left: 18pt;
}

/* The following are for the chat box, on display and on hide */
iframe.fb_customer_chat_bounce_in_v2 {
  left: 9pt;
}

iframe.fb_customer_chat_bounce_out_v2 {
  left: 9pt;
}