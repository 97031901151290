#booking-md-lg table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

#booking-xs-sm {
  display: none;
}

@media only screen and (max-width: 991px) {
  #booking-xs-sm {
    display: none;
  }

  .analytics-container {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  #booking-md-lg {
    display: none;
  }

  #booking-xs-sm {
    display: block;
  }
}
