.ant-drawer .ant-drawer-header {
  font-size: 25px;
  letter-spacing: 0.46px;
  background-color: #f9f9f9;
  color: #42413d;
}
.ant-drawer .ant-drawer-title {
  color: #712B81;
  font-weight: bold;
}

.ant-form label {
  font-weight: bold;
  color: #712B81;
  text-transform: uppercase;
}

@media (max-width: 575.98px) {
  .drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
