.ant-modal-title {
    text-align: center;
    font-size: x-large;
}

.ant-modal-header {
    padding: 32px 24px 0px 24px;
    border-bottom: 0px;
}

.ant-modal-body {
    padding: 16px 24px 24px 24px;
}

.ant-modal-footer {
    border-top: 0px;
}