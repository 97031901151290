.material-input {
  border: 0px;
  font-size: 16px;
  border-radius: 5px !important;
  /* border-bottom: 1px solid #767676; */
}

/* .material-input:focus,
.material-input:hover,
.material-input:active {
  border: none;
  border-radius: none;
  border-bottom: 1px solid #767676;
  box-shadow: none;
} */


.add-package-label {
  font-size: 12px;
  color: #712B81;
}

.add-package-label-container {
  margin-bottom: 20px;
}

.card-package-size {
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 9px;
  color: #707070;
  font-size: 12px;
  margin-top: 10px;
}

.card-shipping-fee .ant-card-body {
  padding: 8px;
}

.card-shipping-price {
  font-size: 24px;
  color: #00B4A9;
}

.card-package-size:focus,
.card-package-size:hover,
.card-package-size-active {
  cursor: pointer;
  color: #fff;
  background-color: #00B4A9;
  border: 1px solid #00B4A9;
}

.btn-remove-border-bottom {
  border-bottom: none;
}

.btn-border-bottom {
  border-bottom: 1px solid #d2d2d2;
}

.font-sm {
  font-size: 12px;
}

.a-tag {
  color: #007bff;
  cursor: pointer;
}

.promocode-input-style {
  border-color: #D9D9D9 !important;
  font-size: 13px !important;
}

.promocode-input-style>input {
  color: #CA4A30 !important;
}

.promocode-input-style:hover>.ant-input:hover {
  border-color: #D9D9D9 !important;
}

.promocode-input-style>.ant-input:focus {
  border-color: #D9D9D9 !important;
  box-shadow: none !important;
}

.promocode-btn-style {
  width: 20% !important;
  color: #fff !important;
  background: #B9B9B9 !important;
  border: 1px solid #D9D9D9 !important;
  font-size: 13px !important;
  border-radius: 0px !important;
}

.promocode-btn-style:active {
  outline: 0 !important;
}

.promocode-btn-style:focus {
  outline: 0 !important;
}

.promocode-heading-styles {
  margin-bottom: 15px !important;
}

.promocode-btn-active-style {
  background-color: #712B81 !important;
  color: white !important;
  border-radius: 0px !important;
}

.promocode-formitem-style {
  margin-bottom: 10px !important;
  width: 100%;
}

.promocode-input-style>.ant-input {
  border-radius: 0px !important;
}

.invalid-txt-style {
  color: #CA4A30;
  font-size: 11px;
}

.valid-txt-style {
  color: #11D045;
  font-size: 11px;
}

.ant-input-clear-icon {
  color: #CA4A30 !important;
}

.textGreen>.ant-input {
  color: #11D045 !important;
}

.font-size {
  font-size: 14px;
}